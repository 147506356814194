<template>
  <div class="modal-backdrop" v-if="showModal">
    <div class="modal-content">
      <div class="modal-backdrop__header">
        <h3>Inicio de sesión</h3>
        <button class="close-button" @click="closeModal">
          <span class="material-symbols-rounded">close</span>
        </button>
      </div>
      <!-- Contenido de tu modal aquí -->
      <form class="login__form-home form__desktop desktop--form" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">

          <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
          <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />

          <!-- User Name -->
          <div class="login__userName">
              <i class="fas fa-user-circle"></i>
              <label for="account">Nombre de Usuario:</label>
              <input class="login__form--inputs" type="text" placeholder="Nombre de Usuario" name="account" id="account">
          </div>

          <!-- Password -->
          <div class="login__password">
              <i class="fas fa-lock-alt"></i>
              <label for="password">Contraseña:</label>
              <input class="login__form--inputs" type="password" name="password" placeholder="Contraseña" id="password">
          </div>

          
          <div class="login__buttons">
              <button class="btn-login primary-button primary-button__loggin" type="submit">Ingresar</button>
          </div>
      </form>
      <form class="login__form-home form__desktop mobile--form" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">

          <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
          <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />

          <!-- User Name -->
          <div class="login__userName">
              <i class="fas fa-user-circle"></i>
              <label for="account">Nombre de Usuario:</label>
              <input class="login__form--inputs" type="text" placeholder="Nombre de Usuario" name="account" id="account">
          </div>

          <!-- Password -->
          <div class="login__password">
              <i class="fas fa-lock-alt"></i>
              <label for="password">Contraseña:</label>
              <input class="login__form--inputs" type="password" name="password" placeholder="Contraseña" id="password">
          </div>

          
          <div class="login__buttons">
              <button class="btn-login primary-button primary-button__loggin" type="submit">Ingresar</button>
          </div>
      </form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ModalComponent',
    setup:() => {
      const DGS_SITEID = 666;
      const backendUrl = "mxb.mx";
      
      return {
        backendUrl,
        DGS_SITEID 
      }
    },
    data() {
      return {
        showModal: false
      }
    },
    methods: {
      openModal() {
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      }
    }
  }
</script>