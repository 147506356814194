<template>

  <header class="header">
    <div class="header-content max-container">
      <router-link @click="scrollToTop" to="./"><img src="@/assets/images/mxb-logo.png" alt="logo"></router-link>
      <form class="login__form" name="LoginForm" action="javascript:void(0)" onsubmit="BackEndLogin(this); return false">
        <div class="login__form--item">
          <label class="login__form--label" for="username">Nombre de Usuario</label>
          <input class="login__form--input" name="username" id="username" type="text" required placeholder="Nombre de Usuario"/>
        </div>

        <div class="login__form--item">
          <label class="login__form--label" for="password">Contraseña</label>
          <input class="login__form--input" type="password" name="password" id="password" required placeholder="Contraseña"/>
        </div>

        <div class="login__btns-login">
          <input type="submit" class="primary-button primary-button__loggin" name="send" id="send" value="Ingresar">
          <input type="hidden" name="BackEndUrl" :value="`https://betslip.${BackEndUrl}`">
        </div>

        <div class="text-center mt-2 error-msg"> 
          <span name="msj_loading" id="id-login-loading"></span>
          <small class="text-danger" name="msj_error_lg"></small>
        </div>
      </form>
      <a class="link-clasic" href="#" @click="openModal">Versión clásica</a>
      <ModalComponent ref="modal" />
    </div>
  </header>
</template>

<script>
    import ModalComponent from '@/components/modal.vue'

  export default {
    name: "HeaderComponent",
    components: {
      ModalComponent
    },
    methods:{
      openModal(){
        this.$refs.modal.openModal();
      }
    },
    setup(){
      const BackEndUrl = "mxb.mx"; 
      return{
        BackEndUrl 
      }
    }
  };
</script>
