<template>
  <footer class="footer">
    <div class="footer-content">
      <warning-component/>
      <div class="list-container">
        <ul class="footer-content__list">
          <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routesColumn1" :key="index">
            <router-link @click="scrollToTop" :to="route.route">{{ route.name }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-content__rights">
      <p>COPYRIGHT 2024 MXB, TODOS LOS DERECHOS RESERVADOS</p>
    </div>
    <mobile-menu/>
  </footer>
</template>


<script>
  import WarningComponent from "@/components/warning.vue"
  import MobileMenu from '@/components/mobile-menu.vue'

  export default {
    methods: {
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    name: "FooterComponent",
    components: {
      WarningComponent,
      MobileMenu
    },
    setup() {
      return {
        routesColumn1: [
          {
            name       :  'Deportes',
            route      :  'sportsbook'
          },
          {
            name       :  'Reglas de Deportes',
            route      :  'sports-rules'
          },
          {
            name       :  'Reglas de Caballos',
            route      :  'racebook-rules'
          },
          {
            name       :  'Reglas de Casino',
            route      :  'casino-rules'
          },
        ]
      }
    },
  };
</script>
