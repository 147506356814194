<template>
  <section class="warning-container">
    <h4>JUEGA CON RESPONSABILIDAD</h4>
    <p>Los juegos con apuestas están prohibidos para menores de edad. Juega de manera RESPONSABLE y con el principal propósito de entretenimiento, diversión y esparcimiento.</p>
  </section>
</template>

<script>
  export default {
    name: "WarningComponent",
    setup() {
    },
  };
</script>
